<template>
    <div id="wangeditor">
        <div ref="editorDescElem" style="text-align:left"></div>
    </div>  
</template>
<script>
import E from 'wangeditor'
import hljs from 'highlight.js'
import 'highlight.js/styles/monokai-sublime.css'
export default {
  name: 'editorDescElem',
  data () {
    return {
      editor: null,
      editorContent: ''
    }
  },
  props: ['catchData', 'content'],    // 接收父组件的方法
  watch: {
    content () {
      this.editor.txt.html(this.content)
    }
  },
  methods:{
    clean(msg){
      this.editor.txt.html(msg)
    },
    append(msg){
      this.editor.txt.append('<p>'+msg+'</p>')
    }
  },
  mounted () {
    this.editor = new E(this.$refs.editorDescElem)

    // 默认情况下，只有 IE 和 旧版 Edge 会使用兼容模式，如果需要在其它浏览器上也使用兼容模式，可以在函数内进行判定
    this.editor.config.compatibleMode = function () {
        // 返回 true 表示使用兼容模式；返回 false 使用标准模式
        return true
    }
    // 配置触发 onchange 的时间频率，默认为 200ms
    // this.editor.config.onchangeTimeout = 500; // 修改为 500ms
    this.editor.config.height = 200
    this.editor.config.placeholder = '请描述工作内容';
    this.editor.highlight = hljs;
    
    this.editor.config.uploadImgServer = 'https://api.gradgroup.cn/ftp/ftp/upload';
    this.editor.config.uploadImgMaxLength = 10;
    this.editor.config.uploadImgHeaders = {
      Authorization: 'Bearer '+window.localStorage.getItem("wtaccess_token")
    }
    this.editor.config.uploadFileName = 'file'
    this.editor.config.uploadImgHooks = {
      // 图片上传并返回了结果，想要自己把图片插入到编辑器中
      // 例如服务器端返回的不是 { errno: 0, data: [...] } 这种格式，可使用 customInsert
      customInsert: function(insertImgFn, result) {
          // result 即服务端返回的接口
          // console.log('customInsert', result)
          if(result.code==200){
            var src = result.data.httpHost+result.data.filePath;
            // insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
            insertImgFn(src)
          }
      }
    }
    this.editor.config.showLinkImg = false
    this.editor.config.onchange = (html) => {
      this.editorContent = html
      this.catchData(this.editorContent)  // 把这个html通过catchData的方法传入父组件
    }
    this.editor.config.menus = [
        'head',
        'bold',
        'fontSize',
        'fontName',
        'italic',
        'underline',
        'strikeThrough',
        'indent',
        'lineHeight',
        'foreColor',
        'backColor',
        'link',
        'list',
        'todo',
        'justify',
        'quote',
        'emoticon',
        'image',
        'video',
        'table',
        'code',
        'splitLine',
        'undo',
        'redo',
    ]
    this.editor.config.emotions = [
        {
            title: '表情',  // tab 的标题
            type: 'emoji', // 'emoji' / 'image'
            // emoji 表情，content 是一个数组即可
            content: '😀 😃 😄 😁 😆 😅 😂 😊 😇 🙂 🙃 😉 😓 😪 😴 🙄 🤔 😬 🤐 😍 😘 😙 🤗 😎 😡 😱 😷 🤪 🤩 🤬 🤮 🥰 🙈 🙉 👀 💤 💩 👏 👍 👎 👊 🤝 👌 💪 👆 👇 👈 👉 👨‍💻 👩‍💻 🌺 🌹 🌼 🌧 💦 🍎 🍻 🍺 🧧 ❌ ❗️ ❓ 💯 ✅ ❎ ☑️ 🔘 🇨🇳'.split(/\s/),
        }
    ]
    
    this.editor.create()     // 创建富文本实例
    if (!this.content) {
      // this.editor.txt.html('请编辑内容1')
    }
  }
}
</script>
<style lang="scss" rel="stylesheet/scss">
    #wangeditor {
      width: 50rem;
    }  
</style>