<template>
  <section>


    <el-dialog title="任务进度量化指标库配置列表"
               :visible.sync="zhibiaopeizhi"
               v-model="zhibiaopeizhi"
               :close-on-click-modal="false"
               center
               @closed="checkclose" 
               append-to-body>
      
      <el-radio-group v-model="radioPublic" size="mini" @change="handleRefreshZhibiaolist">
        <el-radio-button :label="false">私有</el-radio-button>
        <el-radio-button :label="true">公共</el-radio-button>
      </el-radio-group>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <span style="display:inline-block"><el-input size="mini" v-model="searchKey" :clearable="true" placeholder="输入指标名称以筛选"></el-input></span>
      <el-button type="default" icon="el-icon-search" size="mini" @click="handleRefreshZhibiaolist">筛选</el-button>
      <span v-show="!radioPublic">
        &nbsp;
        <el-button type="default" icon="el-icon-plus" size="mini" @click="handleAddZhibiao">新增</el-button>
        <el-button type="default" icon="el-icon-minus" size="mini" @click="handleDelZhibiao">移除</el-button>
        <!-- <el-button type="default" icon="el-icon-open" size="small" @click="handleChangeZhibiaoStatus">调整公开状态</el-button> -->
        <!-- <el-button type="default" icon="el-icon-share" size="small">复制</el-button> -->
      </span>
      <el-table
        :data="zhibiaolist"
        highlight-current-row
        @row-dblclick="handleChoose"
        @current-change="selectCurrentZhibiaoRow"
        style="width: 100%">
        <el-table-column prop="TemplateName" min-width="120px" label="指标配置名称" ></el-table-column>
        <el-table-column prop="IsPublic" label="是否公开" min-width="50px" >
          <template scope="scope">
            <el-tag v-if="!scope.row.IsPublic" type="">私有</el-tag>
            <el-tag v-else-if="scope.row.IsPublic" type="success">公开</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="UseCount" label="使用数量" align="center" min-width="50px" ></el-table-column>
        <el-table-column prop="CreatedBy" label="创建人" min-width="50px" ></el-table-column>
        <!-- <el-table-column prop="CreatedDpt" label="创建部门" ></el-table-column> -->
        <el-table-column prop="CreateTime" label="创建时间" min-width="100px" ></el-table-column>
        <el-table-column prop="Remark" :show-overflow-tooltip="true" label="备注" ></el-table-column>
        <el-table-column prop="Operation" label="操作" min-width="90px" >
          <template scope="scope">
            <el-button v-show="!radioPublic" @click="handleZhibiaoEdit(scope.row)" type="text">编辑</el-button>
            <el-popover
              placement="bottom"
              width="600"
              trigger="click">
              <el-table :data="lookdetail">
                <el-table-column property="FieldName" min-width="100px" label="指标项"></el-table-column>
                <el-table-column property="FieldUnit" align="center" label="值单位"></el-table-column>
                <el-table-column property="FieldType" align="center" label="值类型">
                  <template scope="scope">
                    <span>{{formatType(scope.row.FieldType)}}</span>
                  </template>
                </el-table-column>
                <el-table-column property="FieldLength" align="center" label="值长度"></el-table-column>
                <el-table-column property="FieldMustInput" align="center" label="是否必填">
                  <template scope="scope">
                    <span>{{scope.row.FieldMustInput?'是':'否'}}</span>
                  </template>
                </el-table-column>
                <!-- <el-table-column property="FieldDefault" align="center" label="默认值"></el-table-column> -->
              </el-table>
              <el-button slot="reference" @click="handleLookTemplateDetail(scope.row.Id)" type="text">预览</el-button>
            </el-popover>
            
            <!-- <el-button v-show="!radioPublic" @click="handleZhibiaoRename(scope.row)" type="text">重命名</el-button> -->
            &nbsp;&nbsp;<el-button @click="handleChoose(scope.row)" type="text">选择</el-button>

          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <el-pagination align='center'
                    @current-change="handleCurrentChangeZb"
                    :current-page="currentzbPage"
                    :page-size="pageSizezb"
                    layout="prev, pager, next"
                    :page-count="totalzb"
                    :total="totaldatazb">
      </el-pagination>
    </el-dialog>

    <zhibiaoconfigadd v-if="addzhibiao" @closeFunction="checkzhibiaoaddclose" :type="1" :data="editrow" ></zhibiaoconfigadd>

  </section>
</template>
<script>
import store from '../store'
import util from '../../util/date'
import { Loading } from 'element-ui'
import {
  AddTemplate,DelTemplate,RenameTemplate,ChangeTemplatePublicStatus,QueryPageTemplateByUserCode,QueryTemplateDetailByTemplateId
} from '../api/oa'
import dialogDrag from '../../util/dialogDrag'
import zhibiaoconfigadd from '../components/zhibiaoconfigadd.vue'
export default {
  name: 'zhibiaoconfig',
  components: { util, dialogDrag, zhibiaoconfigadd },
  data() {
    return {
      editrow: null,
      loading: '',
      searchKey:'',
      currentzbPage: 1, // 当前页码
      totalzb: null, // 总条数
      totaldatazb: null,
      pageSizezb: 5, // 每页的数据条数
      pagezb: 1,
      currentRow: null,
      currentZhibiaoRow: null,
      zhibiaolist:[],
      lookdetail:[],
      zhibiaopeizhi: false,
      zhibiaoDetail:[],
      addzhibiao: false,
      chooseRow:null,
      addzhibiaoForm:{
        IsPublic:false
      },
      typeList: [
        { label: '数字', value: 1 },
        { label: '文本', value: 2 },
        // { label: '日期', value: 3 },
        // { label: '时间', value: 4 }
      ],
      mustinputList:[
        { label: '必填', value: true },
        { label: '非必填', value: false },
      ],
      radioPublic:false,
      addzhibiaoFormRules:{
        TemplateName: [
          { required: true, message: '请填写指标配置名称', trigger: 'blur' }
        ],
        IsPublic: [
          { required: true, message: '请选择是否公开', trigger: 'blur' }
        ],
      },
      validRules: {
        name: [
          { required: true, message: '指标项必须填写' },
        ],
        // unit: [
        //   { required: true, message: '指标值单位必须填写' },
        // ],
      },
    }
  },
  props: ['headerText', 'data', 'type'], // 接收父组件的方法
  watch: {
    content() {
      // this.editor.txt.html(this.content)
    }
  },
  methods: {
    checkzhibiaoaddclose(data)
    {
      this.addzhibiao = false;
      if(data.added==1)
      {
        this.handleRefreshZhibiaolist();
      }
    },
    handleChoose(row)
    {
      // console.log(row);
      this.chooseRow = row;
      this.zhibiaopeizhi = false;
    },
    formatMustinput (value) {
      for(var a=0;a<this.mustinputList.length;a++)
      {
        if(this.mustinputList[a].value==value)
        {
          return this.mustinputList[a].label;
        }
      }
      return ''
    },
    formatType (value) {
      for(var a=0;a<this.typeList.length;a++)
      {
        if(this.typeList[a].value==value)
        {
          return this.typeList[a].label;
        }
      }
      return ''
    },
    handleSubmitTemplate()
    {
      this.$refs.addzhibiaoForm.validate((valid) => {
        if (!valid) {
          this.$message({
            message: '请完整填写表单',
            type: 'warning'
          });
          return;
        }else{
          const $table = this.$refs.xTable
          const { insertRecords, removeRecords, updateRecords } = $table.getRecordset()
          // console.log(insertRecords)
          if(insertRecords.length==0)
          {
              this.$message({
                message: '请填写指标配置项',
                type: 'warning'
              });
              return;
          }
          var iserr = false;
          for(var a=0;a< insertRecords.length;a++)
          {
            if(!insertRecords[a].name.trim())
            {
              this.$message({
                message: '请完整填写指标配置项',
                type: 'warning'
              });
              iserr = true;
              break;
            }
          }
          if(!iserr)
          {
            this.$confirm("确定要提交此指标配置吗？", "提示", {}).then(() => {
              var para =Object.assign({},this.addzhibiaoForm);
              var detail = new Array();
              insertRecords.forEach(element => {
                var obj={
                  FieldName:element.name,
                  FieldUnit:element.unit,
                  FieldType:element.type,
                  FieldLength:element.length,
                  FieldMustInput:element.mustinput,
                  // FieldDefault:element.default
                }
                detail.push(obj);
              });
              para.Details=detail;
              // console.log(para)
              
              AddTemplate(para).then((res) => {
                // console.log(res);
                if(res.status==200)
                {
                  this.$message({
                    message: '新增指标配置成功',
                    type: 'success'
                  });
                  this.addzhibiao = false;
                  this.radioPublic = this.addzhibiaoForm.IsPublic;
                  this.queryTemplateList();
                }
              })
            }).catch(()=>{});
          }
        }
      })
    },
    handleDelZhibiaoLine()
    {
      const xtable = this.$refs.xTable;
      xtable.removeCheckboxRow();
    },
    handleAddZhibiaoLine(row)
    {
      const xtable = this.$refs.xTable;
      const record = {};
      const { row: newRow } = xtable.insertAt(record, row)
      xtable.setActiveCell(newRow, 'name')
    },
    handleAddZhibiao()
    {
      this.editrow=null;
      this.zhibiaoDetail = [];
      this.addzhibiao = true;
    },
    handleDelZhibiao()
    {
      let row = this.currentZhibiaoRow;
      if (!row) {
        this.$message({
          message: "请选择要移除的指标配置",
          type: "warning",
        });
        return;
      }
      var user = JSON.parse(window.localStorage.user);
      if(row.CreatedId!=user.sub)
      {
        this.$message({
          message: "当前登录人非指标配置创建者，无法删除！",
          type: "warning",
        });
        return;
      }
      // if(row.UseCount>0)
      // {
      //   this.$message({
      //     message: '此指标已有任务使用，无法删除！',
      //     type: 'warning'
      //   });
      //   return;
      // }

      this.$confirm("确定要将选中的指标配置删除吗？", "提示", {}).then(() => {
        var templateId = row.Id;
        let para = { templateId: templateId };
        DelTemplate(para).then((res) => {
          // console.log(res);
          if(res.status==200)
          {
            this.$message({
              message: '删除指标配置成功',
              type: 'success'
            });
            this.queryTemplateList();
          }
        })
      }).catch(()=>{});
    },
    handleZhibiao()
    {
      var user = JSON.parse(window.localStorage.user);
      this.addzhibiaoForm.CreatedId = user.sub;
      this.addzhibiaoForm.CreatedBy = user.name;
      this.addzhibiaoForm.CreatedDptId = window.localStorage.deptId;
      this.addzhibiaoForm.CreatedDpt = window.localStorage.deptName;
      this.addzhibiaoForm.CreatedByStr=this.addzhibiaoForm.CreatedDpt+'-'+user.name;
      this.zhibiaopeizhi = true;
      this.queryTemplateList();
    },
    queryTemplateList()
    {
      var user = JSON.parse(window.localStorage.user);
      let para = { 
        userCode: user.sub,
        searchKey: this.searchKey,
        ispublic:this.radioPublic,
        pageIndex:this.pagezb,
        pageSize:this.pageSizezb
      };
      QueryPageTemplateByUserCode(para).then((res) => {
        // console.log(res)
        this.totalzb = res.data.response.pageCount;
        this.totaldatazb = res.data.response.dataCount;
        var data = res.data.response.data;
        // console.log(data)
        this.zhibiaolist = data;
      });
    },
    handleLookTemplateDetail(templateId)
    {
      let para = { templateId: templateId };
      QueryTemplateDetailByTemplateId(para).then((res) => {
        // console.log(res)
        this.lookdetail = res.data.response;
      });
    },
    handleZhibiaoEdit(row)
    {
      // console.log(row);
      // if(row.UseCount>0)
      // {
      //   this.$message({
      //     message: '此指标已有任务使用，无需编辑',
      //     type: 'warning'
      //   });
      //   return;
      // }
      this.addzhibiao = true;
      this.editrow = row;
    },
    handleZhibiaoRename(row)
    {
      var templateId = row.Id;
      this.$prompt('输入新配置名称', '指标配置重命名', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue:row.TemplateName
      }).then(({ value }) => {
        row.TemplateName = value;

        let para = { templateId: templateId,newname:value };
        RenameTemplate(para).then((res) => {
          // console.log(res);
          if(res.status==200)
          {
            this.$message({
              message: '配置名称已修改',
              type: 'success'
            });
            this.queryTemplateList();
          }
        })
      }).catch(() => { });
    },
    handleChangeZhibiaoStatus()
    {
      let row = this.currentZhibiaoRow;
      if (!row) {
        this.$message({
          message: "请选择要调整的指标配置",
          type: "warning",
        });
        return;
      }
      var user = JSON.parse(window.localStorage.user);
      if(row.CreatedId!=user.sub)
      {
        this.$message({
          message: "当前登录人非指标配置创建者，无法调整状态！",
          type: "warning",
        });
        return;
      }
      var status = row.IsPublic?'私有':'公开';
      this.$confirm("确定要将此指标调整为「"+status+"」状态吗？", "提示", {}).then(() => {
        var templateId = row.Id;
        let para = { templateId: templateId,ispublic:!row.IsPublic };
        ChangeTemplatePublicStatus(para).then((res) => {
          // console.log(res);
          if(res.status==200)
          {
            this.$message({
              message: '修改成功',
              type: 'success'
            });
            this.queryTemplateList();
          }
        })
      }).catch(()=>{});

    },
    handleRefreshZhibiaolist()
    {
      this.pagezb = 1;
      this.queryTemplateList();
    },
    selectCurrentZhibiaoRow (val)
    {
      this.currentZhibiaoRow = val;
    },
    handleCurrentChangeZb (val) {
      this.pagezb = val;
      this.queryTemplateList();
    },
    myname() {
      var user = JSON.parse(window.localStorage.user)
      return user.name
    },
    checkclose() {
      this.pValue1 = 0
      this.$emit('closeFunction', this.chooseRow)
    },
    Loadingstart() {
      this.loading = Loading.service({
        lock: true,
        text: '加载中...',
        background: 'rgba(0,0,0,0.1)'
      })
    },
    Loadignend() {
      this.loading.close()
    },
  },
  mounted() {
    
    if (this.headerText) {
      this.myheadText = this.headerText
    }
    if (this.type) {
      this.mytype = this.type
      console.log(this.mytype)
    }
    var user = JSON.parse(window.localStorage.user);
    this.addzhibiaoForm.CreatedId = user.sub;
    this.addzhibiaoForm.CreatedBy = user.name;
    this.addzhibiaoForm.CreatedDptId = window.localStorage.deptId;
    this.addzhibiaoForm.CreatedDpt = window.localStorage.deptName;
    this.addzhibiaoForm.CreatedByStr=this.addzhibiaoForm.CreatedDpt+'-'+user.name;
    this.zhibiaopeizhi = true;
    this.queryTemplateList();
  }
}
</script>
<style scoped>
.active {
  background-color: #00000014;
}
/deep/ .el-collapse-item__arrow {
  margin: 0;
  transition: transform 0.3s;
  font-weight: 300;
}
/deep/ .el-dialog.is-fullscreen {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  height: 100%;
  overflow: hidden;
}
</style>
